import React from 'react';
import { Typography, CardMedia } from '@one-thd/sui-atomic-components';
import classNames from 'classnames/bind';
import { bool } from 'prop-types';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { SpecialBuyNavButton } from '../SpecialBuyNavButton/SpecialBuyNavButton';
import { useSpecialBuy } from '../../context/SpecialBuyProvider';

const SpecialBuyHeader = ({ isUppercaseHeader }) => {
  const {
    endDate,
    logoSrc,
    tagline,
    specialBuyType,
    isFallbackBanner,
    isCondensedLayout,
    isSpecialBuyDataLoading,
  } = useSpecialBuy();
  const isSbotd = specialBuyType === 'sbotd';

  const style = {
    buttonRightHeader: classNames('sui-w-full',
      { 'sui-pr-4': !isCondensedLayout }
    ),
    badgeWrapper: classNames('sui-mt-1 sui-min-w-[80px]', // eslint-disable-line no-use-before-define
      {
        'sui-mr-4': !isCondensedLayout,
        'sui-ml-4': isCondensedLayout
      }
    ),
    headerContainer: classNames('sui-flex sui-w-full',
      { 'sui-justify-between': isCondensedLayout }
    ),
  };

  let subTextMessage;
  if (isFallbackBanner && !isSpecialBuyDataLoading) {
    const endTimeText = isSbotd ? 'Today' : 'This Week';
    subTextMessage = `Browse All Departments, Savings End ${endTimeText}!`;
  } else {
    subTextMessage = isSbotd ? 'Today Only! Free Delivery' : 'Online Only + Free Delivery';
  }

  // added a timezone offset to the time used for the countdown
  // without the offset, the the countdown timer displays the wrong time.
  const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
  // eslint-disable-next-line no-multi-assign
  const todaysDateWithTimezone = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
  const consistentEndDate = isSbotd
    ? todaysDateWithTimezone
    : endDate;

  const countdownTimer = (
    <CountdownTimer
      isPromotionTimer
      endDate={consistentEndDate}
      shouldUseMessagingString
      shouldIncludeSvg
    />
  );

  const badge = (
    <CardMedia
      component="img"
      alt="Special Buy Logo"
      title="Special Buy Logo"
      src={`https://assets.thdstatic.com/images/v1/${logoSrc}`}
      loading="lazy"
    />
  );
  const specialBuyCountDownTimer = !isFallbackBanner && consistentEndDate?.length && countdownTimer;
  const condensedLayoutBadge = isCondensedLayout && (
    <div className={style.badgeWrapper}>
      {badge}
    </div>
  );
  const carouselBadge = !isCondensedLayout && (
    <div className={style.badgeWrapper}>
      {badge}
    </div>
  );

  const headerVariant = isUppercaseHeader ? 'h2' : 'h3';
  const headerWeight = isUppercaseHeader ? 'display' : 'bold';

  return (
    <section className="sui-flex">
      <div className={style.buttonRightHeader}>
        <div className={style.headerContainer}>
          {carouselBadge}
          <div>
            <Typography
              variant={headerVariant}
              weight={headerWeight}
              uppercase={isUppercaseHeader}
            >
              {tagline}
            </Typography>
            <Typography variant="body-lg">
              {subTextMessage}
            </Typography>
            {isCondensedLayout && !isFallbackBanner && (
              <div className="sui-my-4">
                {specialBuyCountDownTimer}
              </div>
            )}
          </div>
          {condensedLayoutBadge}
        </div>
        {!isCondensedLayout && !isFallbackBanner && (
          <div className="sui-my-4">
            {specialBuyCountDownTimer}
          </div>
        )}
      </div>
      {!isCondensedLayout && <div><SpecialBuyNavButton /></div>}
    </section>
  );
};

SpecialBuyHeader.displayName = 'SpecialBuyHeader';

SpecialBuyHeader.propTypes = {
  isUppercaseHeader: bool,
};

SpecialBuyHeader.defaultProps = {
  isUppercaseHeader: false,
};

export { SpecialBuyHeader };
