/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import {
  shape, string, arrayOf, func, bool
} from 'prop-types';
import classNames from 'classnames';
import { CardMedia, Typography } from '@one-thd/sui-atomic-components';
import { noop } from '../../utils/utils';

const CategoryTab = ({ isSelected, category, onClick }) => {

  const buttonClass = classNames(
    'sui-flex sui-border-solid sui-rounded-base sui-p-2 sui-w-[224px] sui-btn sui-justify-between', {
      'sui-border-primary sui-border-1': !isSelected,
      'sui-border-[#F96302] sui-border-2': isSelected,
    }
  );

  return (
    <button
      onClick={onClick}
      type="button"
      className={buttonClass}
    >
      <CardMedia
        component="img"
        className="sui-w-[30px] sui-h-[30px]"
        src={category.image.url}
        alt={`${category.name} product image`}
        loading="lazy"
      />
      <Typography
        weight={isSelected && 'bold'}
        variant="body-xs"
        lineClamp={2}
        align="left"
        height="tight"
      >
        <div className="sui-pl-1 sui-w-36">
          {category.name}
        </div>
      </Typography>
      <Typography
        weight={isSelected && 'bold'}
        variant="body-xs"
        lineClamp={1}
        height="tight"
      >
        <div className="sui-py-2">
          ({category.itemIds.length})
        </div>
      </Typography>
    </button>
  );
};

CategoryTab.displayName = 'CategoryTab';

CategoryTab.propTypes = {
  isSelected: bool,
  category: shape({
    name: string.isRequired,
    itemIds: arrayOf(string.isRequired).isRequired,
    categoryId: string.isRequired,
    image: shape({
      url: string.isRequired
    }).isRequired
  }).isRequired,
  onClick: func,
};

CategoryTab.defaultProps = {
  isSelected: false,
  onClick: noop,
};

export { CategoryTab };
