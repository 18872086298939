import React, { useMemo } from 'react';
import { string, oneOf } from 'prop-types';

import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import { CategoryTab } from '../CategoryTab/CategoryTab';
import { MobileCategoryTab } from '../MobileCategoryTab/MobileCategoryTab';

const generateKey = (categoryId, index) => `category-tab-${categoryId || index}`;

const SpecialBuyCategories = ({ entryId, specialBuyType }) => {
  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    isCondensedLayout,
    isSpecialBuyDataLoading
  } = useSpecialBuy();

  const categoriesMemoized = useMemo(() => {
    if (isCondensedLayout) {
      const impressionData = {
        id: entryId,
        name: specialBuyType === 'sbotd' ? 'SBOTD' : 'SBOTW',
        component: 'SpecialBuyBanner',
        type: 'product',
        position: '',
        category: ''
      };

      const mobileCategoryTab = categories.map((category, index) => (
        <MobileCategoryTab
          category={category}
          entryId={entryId}
          index={index + 1}
          key={generateKey(category.categoryId, index)}
        />
      ));

      return (
        <ImpressionProvider data={impressionData}>
          {mobileCategoryTab}
        </ImpressionProvider>
      );
    }

    return categories.map((category, index) => (
      <CategoryTab
        category={category}
        isSelected={category === selectedCategory}
        key={generateKey(category.categoryId, index)}
        onClick={() => setSelectedCategory(category)}
      />
    ));

  }, [
    categories,
    entryId,
    isCondensedLayout,
    selectedCategory,
    setSelectedCategory,
    specialBuyType
  ]);

  const CategoryPlaceholder = () => {
    return (
      <div data-testid="product-pod-placeholder" className="sui-min-h-475px">
        <Skeleton
          grow
          data-component="CategoryPlaceholder"
          width="700px"
        >
          <SkeletonBlock aspect="square" width="100%" height="100%" />
        </Skeleton>
      </div>
    );
  };

  return (
    <section className="sui-flex sui-gap-3">
      {isSpecialBuyDataLoading ? <CategoryPlaceholder /> : [categoriesMemoized]}
    </section>
  );
};

SpecialBuyCategories.displayName = 'SpecialBuyCategories';

SpecialBuyCategories.propTypes = {
  entryId: string,
  specialBuyType: oneOf(['sbotd', 'pro']).isRequired
};

SpecialBuyCategories.defaultProps = {
  entryId: '',
};

export { SpecialBuyCategories };
